import React from "react";
import AllDataLog from "./AllDataLog";
// import { useDataLogFetchMutation } from "../../../features/dataLog/dataLogApi";
import { useBrriLabDataLogFetchMutation } from "../../../features/brri/brriLabDataLogApi";
import { useEffect } from "react";
import { useState } from "react";
import { ThemeContext } from "../../../context/ThemeContext";
import { useContext } from "react";
import { useTotalRoomFetchQuery } from "../../../features/dataLog/filters/filterApi";
import { useTotalSensorListFetchQuery } from "../../../features/dataLog/filters/filterApi";
import { useBrriLabSensorListQuery } from "../../../features/brri/brriLabDataLogApi";
import negativeValueCalculate from "../../../helper/negativeValueCalculate";
import useTitle from "../../../hooks/useTitle";

function LabDataLogMain() {
  useTitle("Lab Data Log");
  const { data: roomList, isLoading: roomFetchQueryLoading } =
    useTotalRoomFetchQuery();

  const { data: sensorList, isLoading: sensorFetchQueryLoading } =
    useBrriLabSensorListQuery();
  const theme = useContext(ThemeContext);
  // const [dataLogFetch, { data: fetchedData, isLoading, error: responseError }] =
  //   useDataLogFetchMutation();
  const [
    brriLabDataLogFetch,
    { data: fetchedData, isLoading, error: responseError },
  ] = useBrriLabDataLogFetchMutation();
  const [activePage, setActivePage] = useState(1);
  const [dataView, setDataView] = useState(10);
  const [date, setDate] = useState({
    startdate: null,
    enddate: null,
  });
  const [room_id, setRoom_id] = useState(null);
  const [sensor_id, setSensor_id] = useState(null);

  let totalDataCount = 0;
  let tableData = [];
  let element = "";

  useEffect(() => {
    async function dataFetch() {
      if (date?.startdate && room_id && sensor_id) {
        console.log("inside All");
        brriLabDataLogFetch({
          dataview: dataView,
          page: activePage,
          startdate: date?.startdate,
          enddate: date?.enddate,
          room_id: room_id,
          sensor_id: sensor_id,
        });
      } else if (date?.startdate && room_id) {
        console.log("inside startDate and room_id");
        brriLabDataLogFetch({
          dataview: dataView,
          page: activePage,
          startdate: date?.startdate,
          enddate: date?.enddate,
          room_id: room_id,
        });
      } else if (date?.startdate && sensor_id) {
        console.log("inside startDate and sensor_id");
        brriLabDataLogFetch({
          dataview: dataView,
          page: activePage,
          startdate: date?.startdate,
          enddate: date?.enddate,
          sensor_id: sensor_id,
        });
      } else if (date?.startdate) {
        console.log("inside startDate");
        await brriLabDataLogFetch({
          dataview: dataView,
          page: activePage,
          startdate: date?.startdate,
          enddate: date?.enddate,
        });
      } else {
        console.log("inside default");
        await brriLabDataLogFetch({
          dataview: dataView,
          page: activePage,
          // startdate:date?.startdate || "",
          // enddate:date?.enddate || "",
        });
      }
    }
    dataFetch();
    // console.group("inside useEffect");
    // console.log(fetchedData?.total);
  }, [activePage, dataView]);

  const downloadCSVHandler = (e) => {
    e.preventDefault();
    const baseUrl = process.env.REACT_APP_API_URL + "/apiV2";
    const exportURL = `${baseUrl}/lab-datatable-export`;

    if (date?.startdate && room_id && sensor_id) {
      const exportUrlWithDate =
        exportURL +
        `?startdate=${date?.startdate}&enddate=${date?.enddate}&sensor_id=${sensor_id}&room_id=${room_id}`;
      window.location.replace(exportUrlWithDate);
    } else if (date?.startdate && room_id) {
      const exportUrlWithDate =
        exportURL +
        `?startdate=${date?.startdate}&enddate=${date?.enddate}&room_id=${room_id}`;
      window.location.replace(exportUrlWithDate);
    } else if (date?.startdate && sensor_id) {
      const exportUrlWithDate =
        exportURL +
        `?startdate=${date?.startdate}&enddate=${date?.enddate}&sensor_id=${room_id}`;
      window.location.replace(exportUrlWithDate);
    } else if (date?.startdate) {
      const exportUrlWithDate =
        exportURL + `?startdate=${date?.startdate}&enddate=${date?.enddate}`;
      window.location.replace(exportUrlWithDate);
    } else {
      window.location.replace(exportURL);
    }
  };

  const searchSumbitHandler = (e) => {
    e.preventDefault();
    if (date?.startdate && room_id && sensor_id) {
      brriLabDataLogFetch({
        dataview: dataView,
        page: 1,
        startdate: date?.startdate,
        enddate: date?.enddate,
        room_id: room_id,
        sensor_id: sensor_id,
      });
    } else if (date?.startdate && room_id) {
      brriLabDataLogFetch({
        dataview: dataView,
        page: 1,
        startdate: date?.startdate,
        enddate: date?.enddate,
        room_id: room_id,
      });
    } else if (date?.startdate && sensor_id) {
      brriLabDataLogFetch({
        dataview: dataView,
        page: 1,
        startdate: date?.startdate,
        enddate: date?.enddate,
        sensor_id: sensor_id,
      });
    } else {
      brriLabDataLogFetch(date);
    }

    // console.log(fetchedData);
  };

  const roomSelectHandler = (e) => {
    setRoom_id(e.target.value);
  };
  const sensorSelectHandler = (e) => {
    setSensor_id(e.target.value);
  };

  if (!isLoading) {
    const data = fetchedData?.data || [];

    tableData = data.map((item) => {
      const data = new Date(item.created_at);
      const formattedDate = data.toLocaleString();
      if (!item.room_name) {
        return {
          ...item,
          room_name: "Not Assigned",
          level: "Not Assigned",
          created_at: formattedDate,
        };
      } else {
        return {
          ...item,
          created_at: formattedDate,
        };
      }
    });

    totalDataCount = fetchedData?.total || 0;

    if (tableData.length > 0) {
      element = (
        <AllDataLog
          tableData={tableData}
          setActivePage={setActivePage}
          setDataView={setDataView}
          activePage={activePage}
          totalDataCount={totalDataCount}
        />
      );
    } else {
      element = <div>No Data Found</div>;
    }
  }

  return (
    <div className={`${theme}`}>
      <div
        className="theme-text"
        style={{ fontSize: "20px", fontWeight: "bold", paddingBottom: "1rem" }}
      >
        Brri Lab Data Log
      </div>
      <div className="row">
        <div className="col-md-10">
          <form onSubmit={(e) => searchSumbitHandler(e)}>
            <div className="row">
              <div className="form-group col-md-2">
                <label className="theme-text">Start Date </label>
                <input
                  type="date"
                  className="form-control"
                  onChange={(e) =>
                    setDate({ ...date, startdate: e.target.value })
                  }
                  required
                />
              </div>
              <div class="form-group col-md-2">
                <label className="theme-text">End Date</label>
                <input
                  type="date"
                  class="form-control"
                  onChange={(e) =>
                    setDate({ ...date, enddate: e.target.value })
                  }
                  required
                />
              </div>
              <div className="col-md-3 date-search-submit-btn">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={room_id}
                  onChange={roomSelectHandler}
                >
                  <option value="" selected>
                    Select Area
                  </option>
                  {roomList?.map((room) => {
                    return (
                      <option key={room?.room_id} value={room?.room_id}>
                        {room?.room_name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-md-3 date-search-submit-btn">
                <select
                  className="form-select"
                  aria-label="Default select example"
                  value={sensor_id}
                  onChange={sensorSelectHandler}
                >
                  <option value="" selected>
                    Select Sensor
                  </option>
                  {sensorList?.map((sensor) => {
                    return (
                      <option
                        key={sensor?.parameter_id}
                        value={sensor?.parameter_id}
                      >
                        {sensor?.sensor_name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-md-2 date-search-submit-btn">
                <button type="submit" class="btn btn-primary col-md-12">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
        <div className="col-md-2 date-search-submit-btn">
          <button
            type="submit"
            class="btn btn-info col-md-12"
            onClick={(e) => downloadCSVHandler(e)}
          >
            {" "}
            Download CSV
          </button>
        </div>
      </div>
      {/* {element} */}
      <AllDataLog
        tableData={tableData}
        setActivePage={setActivePage}
        setDataView={setDataView}
        activePage={activePage}
        totalDataCount={totalDataCount}
      />
    </div>
  );
}

export default LabDataLogMain;
